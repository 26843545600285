<template>
  <v-app>
    <v-container>
      <v-card
        elevation="0"
        outlined
        color="primary"
        class="rounded-lg mt-10 mb-3 pb-2 pt-1"
      >
        <v-row class="mx-auto">
          <!-- Query info -->
          <v-col cols="8">
            <p class="pa-0 p-week"><b> Query: </b></p>
            <p class="text-justify">{{ currentQuery.query }}</p>
            <p class="p-id mb-0">
              Date: {{ currentQuery.timestamp | formatDate }}
            </p>
            <p class="p-id mb-0">
              Time: {{ currentQuery.timestamp | formatTime }}
            </p>
            <p class="p-id mb-0">Submitted by: {{ currentQuery.email }}</p>
            <p class="p-id mb-0">
              Years searched back:
              {{
                currentQuery.weeks | formatDateRange(currentQuery.customDate)
              }}
            </p>
          </v-col>

          <!-- save button visible on scroll -->
          <v-expand-transition>
            <div
              class="fixed background-dashboard mr-5"
              v-scroll="onScroll"
              v-show="isShown"
            >
              <p class="ml-3 mb-0 mr-0">
                <i>save</i>
              </p>
              <v-btn
                @click="save_selected"
                elevation="3"
                class="ml-3 mb-3 mt-0 mr-0"
                small
                color="info"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </div>
          </v-expand-transition>

          <v-col cols="2">
            <p class="text-right">Last export:</p>
            <p v-if="currentQuery.timeExported" class="text-right mt-0">
              {{ currentQuery.timeExported | formatDate }}
            </p>
            <p v-else class="text-right mt-0">no export yet</p>
          </v-col>

          <!-- save and export -->
          <v-col cols="2" class="text-right mr-0">
            <p class="mb-0 text-right">
              <i>export</i>
            </p>
            <v-btn
              @click="download_nbib"
              elevation="3"
              class="mr-0"
              small
              color="secondary"
            >
              <v-icon color="info">mdi-file-export</v-icon>
            </v-btn>

            <p class="mb-0 text-right">
              <i>save</i>
            </p>
            <v-btn
              @click="save_selected"
              elevation="3"
              class="mr-0"
              small
              color="info"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="mx-auto">
        <v-col>
          <p class="text-justify mb-0">
            <i
              >Articles selected by algorithm: {{ totalArticles }} out of
              {{ currentQuery.pmids.length }}</i
            >
          </p>
        </v-col>
      </v-row>

      <!-- article counter -->
      <v-row class="mt-0">
        <v-col align-self="end" class="">
          <v-card
            elevation="1"
            outlined
            color="primary"
            class="rounded-lg"
            style="display: inline-block"
          >
            <p class="mb-0 pa-2">
              <b>{{ selectedArticles }}</b> of
              <b>{{ totalArticles }}</b> articles selected for export
            </p>
          </v-card>
        </v-col>

        <!-- select all -->
        <v-col class="checkbox-padding-left">
          <p class="text-right ma-0 pa-0">select all</p>
          <v-container fluid class="flex-end pt-0">
            <v-checkbox
              class="mt-0 pt-0"
              v-model="selectAll"
              color="info"
              @change="select_All()"
            >
            </v-checkbox>
          </v-container>
        </v-col>
      </v-row>

      <!-- loading  -->
      <div v-if="loadingQueryDocs">
        <center>
          <h4>loading...</h4>
          <v-progress-circular
            :size="50"
            :width="4"
            indeterminate
            color="info"
          ></v-progress-circular>
        </center>
      </div>

      <!-- savingDocs  -->
      <div v-else-if="savingQueryDocs">
        <center>
          <h4>saving...</h4>
          <v-progress-circular
            :size="50"
            :width="4"
            indeterminate
            color="info"
          ></v-progress-circular>
        </center>
      </div>
      <div v-else>
        <!-- begin for loop -->
        <div v-for="doc in queryDocs" :key="doc.id">
          <!-- <div v-if="doc.score > 0.8"> -->
          <v-row>
            <v-col cols="10">
              <Article
                :abstract="doc.abstract"
                :title="doc.title"
                :authors="doc.authors"
                :pmid="doc.pmid"
                :data="doc.date"
                :journal="doc.journal"
                :score="doc.score"
              />
            </v-col>
            <v-col class="checkbox-padding-left">
              <v-container fluid class="flex-end">
                <v-checkbox color="info" v-model="doc.selected"> </v-checkbox>
              </v-container>
            </v-col>
          </v-row>
          <hr />
          <!-- </div> -->
        </div>
        <!-- end for loop -->
      </div>

      <v-row class="mx-auto mt-5 mb-5">
        <v-col cols="11"></v-col>
        <v-col cols="1" class="text-center background-dashboard pt-0">
          <p class="mb-0 mt-1">
            <i>export</i>
          </p>
          <v-btn
            @click="download_nbib"
            elevation="3"
            class="mr-0"
            small
            color="secondary"
          >
            <v-icon color="info">mdi-file-export</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import Article from "../components/Article.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "QueryViewer",
  components: {
    Article,
  },
  props: {
    qid: String,
  },
  data() {
    return {
      selectAll: false,
      isShown: false,
    };
  },
  computed: {
    ...mapState([
      "queryDocs",
      "loadingQueryDocs",
      "currentQuery",
      "savingQueryDocs",
    ]),
    totalArticles: function () {
      return this.queryDocs.length;
    },
    selectedArticles: function () {
      const arr = this.queryDocs.map((doc) => doc.selected);
      const count = arr.filter(Boolean).length;
      return count;
    },
  },
  methods: {
    ...mapActions([
      "loadQueryDocs",
      "setLastUpdateQuery",
      "saveSelectedQueryDocs",
    ]),
    select_All() {
      if (this.selectAll == false) {
        this.queryDocs.map((doc) => {
          doc.selected = false;
          return doc;
        });
      } else {
        this.queryDocs.map((doc) => {
          doc.selected = true;
          return doc;
        });
      }
    },
    onScroll() {
      if (window.scrollY <= 0) {
        this.isShown = false;
      } else {
        this.isShown = true;
      }
    },
    save_selected() {
      this.saveSelectedQueryDocs();
    },
    getLongest(arr) {
      var longest = arr.reduce(function (a, b) {
        return a.length > b.length ? a : b;
      });
      return longest;
    },
    download_nbib() {
      this.setLastUpdateQuery({
        qid: this.qid,
      });

      const nbib = this.queryDocs.map((doc) => {
        if (doc.selected && doc.nbib) {
          return doc.nbib;
        }
      });

      const blob = new Blob([nbib.join("\n\n")], {
        type: "text/plain",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      const fileName = "export_query.txt";
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);

      this.save_selected();
    },
  },
  mounted() {
    this.loadQueryDocs({
      qid: this.qid,
    });
  },
};
</script>
